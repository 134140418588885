var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 mt-4"},[_c('v-toolbar',{staticStyle:{"height":"6rem"}},[_c('v-toolbar-title',[_c('v-btn',{staticClass:"mr-4 mb-2 mt-6",attrs:{"color":"#1dd1a1"},on:{"click":_vm.consultarAtendimentos}},[_c('v-icon',{attrs:{"center":"","small":"","color":"#FFF"}},[_vm._v("fas fa-search")])],1)],1),_c('v-text-field',{staticClass:"mt-14",attrs:{"label":"Pesquise atendimentos por Data, CPF, especialidade e nome do paciente","color":"green","filled":"","solo-inverted":""},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.consultarAtendimentos.apply(null, arguments)}},model:{value:(_vm.consulta),callback:function ($$v) {_vm.consulta=$$v},expression:"consulta"}})],1),_c('div',{staticClass:"row mt-6"},[_c('div',{staticClass:"col-md-12",staticStyle:{"display":"flex","align-itens":"center","justify-content":"space-between"}},[_c('b-tooltip',{attrs:{"target":`tooltip3-target`,"triggers":"hover"}},[_vm._v(" Página anterior ")]),_c('b-tooltip',{attrs:{"target":`tooltip4-target`,"triggers":"hover"}},[_vm._v(" Próxima página ")]),_c('div',{staticClass:"mt-2"},[_c('h3',[_vm._v("Página "+_vm._s(this.page)+" de "+_vm._s(this.totalPages))])]),_c('div',[_c('v-btn',{staticClass:"mr-1",attrs:{"disabled":this.page == 0 || this.page == 1 ? true : false,"id":"tooltip3-target","color":"#1dd1a1"},on:{"click":_vm.anteriorPage}},[_c('v-icon',{attrs:{"center":"","small":"","color":"#FFF"}},[_vm._v("fas fa-arrow-left")])],1),_c('v-btn',{attrs:{"disabled":this.page == this.totalPages || this.totalPages == 0
                  ? true
                  : false,"id":"tooltip4-target","color":"#1dd1a1"},on:{"click":_vm.proximoPage}},[_c('v-icon',{attrs:{"center":"","small":"","color":"#FFF"}},[_vm._v("fas fa-arrow-right")])],1)],1)],1)])],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('v-simple-table',{attrs:{"fixed-header":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{},[_vm._v("Paciente")]),_c('th',{},[_vm._v("CPF")]),_c('th',{},[_vm._v("Especialidade")]),_c('th',{},[_vm._v("Prontuário")]),_c('th',{},[_vm._v("Opções")])])]),_vm._l((_vm.tempAtendimentos),function(atendimento){return _c('tbody',{key:atendimento.id},[_c('tr',[_c('td',[_vm._v(_vm._s(atendimento.dados_paciente.pessoa.nome))]),_c('td',[_vm._v(_vm._s(atendimento.dados_paciente.pessoa.cpf))]),_c('td',[_vm._v(_vm._s(atendimento.especialidade.descricao))]),_c('td',[_vm._v(_vm._s(atendimento.dados_prontuario.numero))]),_c('td',[(atendimento.dados_prontuario.triagem_existe == true)?_c('router-link',{attrs:{"to":{
                    name: 'prontuarioPainel',
                    params: {
                      dados: atendimento,
                    },
                  }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate }){return [_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('v-btn',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Prontuário'),expression:"'Prontuário'",modifiers:{"hover":true,"top":true}}],staticClass:"mr-2 mb-2 mt-2",attrs:{"color":"#1dd1a1"}},[_c('v-icon',{attrs:{"center":"","small":"","color":"#FFF"}},[_vm._v("fas fa-book-medical")])],1)],1)]}}],null,true)}):_vm._e(),(atendimento.dados_prontuario.triagem_existe == false)?_c('router-link',{attrs:{"to":{
                    name: 'novaTriagem',
                    params: {
                      id: atendimento.paciente_id,
                      id2: atendimento.id,
                      id3: atendimento.especialidade_id,
                      id4: atendimento.dados_prontuario.id,
                    },
                  }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate }){return [_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('v-btn',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Realizar nova triagem'),expression:"'Realizar nova triagem'",modifiers:{"hover":true,"top":true}}],staticClass:"mr-2 mb-2 mt-2",attrs:{"id":`tooltip3-target-${atendimento.id}`,"color":"#1dd1a1"}},[_c('v-icon',{attrs:{"center":"","small":"","color":"#FFF"}},[_vm._v("fas fa-file-alt")])],1)],1)]}}],null,true)}):_c('v-btn',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Triagem já realizada'),expression:"'Triagem já realizada'",modifiers:{"hover":true,"top":true}}],staticClass:"mr-2 mb-2 mt-2",attrs:{"disabled":"","color":"#1dd1a1"}},[_c('v-icon',{attrs:{"center":"","small":"","color":"#FFF"}},[_vm._v("fas fa-file-alt")])],1),(atendimento.dados_prontuario.triagem_existe == false)?_c('v-btn',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Encaminhar para consulta'),expression:"'Encaminhar para consulta'",modifiers:{"hover":true,"top":true}}],staticClass:"mr-2 mb-2 mt-2",attrs:{"disabled":"","color":"#1dd1a1"}},[_c('v-icon',{attrs:{"center":"","small":"","color":"#FFF"}},[_vm._v("fas fa-arrow-right")])],1):_c('v-btn',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Encaminhar para consulta'),expression:"'Encaminhar para consulta'",modifiers:{"hover":true,"top":true}}],staticClass:"mr-2 mb-2 mt-2",attrs:{"color":"#1dd1a1"},on:{"click":function($event){return _vm.encaminharConsulta(
                      atendimento.id,
                      atendimento.paciente_id,
                      atendimento.especialidade_id
                    )}}},[_c('v-icon',{attrs:{"center":"","small":"","color":"#FFF"}},[_vm._v("fas fa-arrow-right")])],1)],1)])])})]},proxy:true}])}),(_vm.tempAtendimentos.length == 0)?_c('div',{staticClass:"d-flex flex-column-fluid flex-center mt-12"},[_vm._m(0)]):_vm._e()],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"text-center mb-10",attrs:{"href":"#"}},[_c('img',{staticClass:"max-h-200px",attrs:{"src":"media/novaImagens/logo.png","alt":""}})])
}]

export { render, staticRenderFns }